import axios from "axios";

/**
 * 获取量产汇总列表
 */
export const fetchOutputSummaryList = params => {
  return axios({
    url: "/dtsum/zongtong/report/statisticalFormsController/productAmountSelect",
    method: "GET",
    params
  });
};
