<template>
  <div id="page-output-summary" class="container">
    <!-- 顶部筛选 -->
    <div class="common-filter-block">
      <div class="common-filter-item">
        <span style="margin-right: 10px">选择时间</span>
        <DatePicker
          style="width: 220px"
          type="daterange"
          placeholder="请选择日期范围"
          :clearable="false"
          v-model="listParam.filterDateRange"
          @on-change="handleDateRangeChange"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">挡车工</span>
        <Select
          clearable
          multiple
          :max-tag-count="2"
          v-model="listParam.filterWorkerNameList"
          style="width: 240px"
          @on-change="handleSelectChange"
        >
          <Option v-for="(item, index) in allWorkerNameList" :value="item.name" :key="index">
            {{ item.name }}</Option
          >
        </Select>
      </div>
    </div>
    <div class="common-action-block">
      <div class="common-action-item" @click="getTableListData">
        <i class="iconfont iconicon-shuaxin"></i>
        刷新
      </div>
      <div class="common-action-item" @click="exportTableList">
        <i class="iconfont iconicon-daochu"></i>
        导出
      </div>
    </div>

    <!-- 表格 -->
    <Table
      border
      show-summary
      :summary-method="handleSummary"
      :loading="loading"
      :columns="tableColumns"
      :data="tableData"
    />

    <!-- 分页 -->
    <Page
      class-name="common-page"
      show-sizer
      show-elevator
      show-total
      :total="listDataTotal"
      :page-size="listParam.pageSize"
      :current="listParam.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
  </div>
</template>

<script>
import { fetchOutputSummaryList } from "./api";

export default {
  name: "OutputSummary",
  data() {
    return {
      allWorkerNameList: [], // 形如 [{name: 'aaa'},{name:'bbb'}]，后端返过来就长这样

      // 首页表格配置
      loading: false,
      listParam: {
        workerName: "", // 挡车工姓名，可以多选  形如 "张三,李四,丁一行"，默认 空字符串"", 搜索全部，
        filterWorkerNameList: [], // 前端用

        startDate: this.moment(Date.now() - 86400000 * 7).format("YYYY-MM-DD"), // 如 '2021-07-16'，精确到天
        endDate: this.moment(new Date()).format("YYYY-MM-DD"),
        filterDateRange: [
          this.moment(Date.now() - 86400000 * 7).format("YYYY-MM-DD"),
          this.moment(new Date()).format("YYYY-MM-DD"),
        ], // 前端用

        pageNum: 1,
        pageSize: 10,
      },
      tableData: [],
      tableColumns: [
        {
          title: "时间",
          key: "date",
          minWidth: 120,
        },
        {
          title: "挡车工",
          key: "person",
          minWidth: 120,
        },
        {
          title: "产量(kg)",
          key: "weight",
          minWidth: 120,
        },
      ],
      summaryRow: {
        // 汇总的数据，表格最后一行
        totalOutput: 0,
      },

      listDataTotal: 0, // 分页器数据总数
    };
  },
  created() {
    this.getTableListData();
  },
  methods: {
    handleDateRangeChange(formatDate, DateObj) {
      this.listParam.startDate = formatDate[0];
      this.listParam.endDate = formatDate[1];

      this.getTableListData();
    },
    handleDateChange(formatDate, DateObj) {
      this.listParam.deliveryDate = formatDate;
      this.getTableListData();
    },

    handleSelectChange() {
      this.listParam.workerName = Array(this.listParam.filterWorkerNameList).join(",");
      this.getTableListData();
    },

    handleSummary({ columns, data }) {
      const sums = {};

      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "汇总",
          };
        } else if (key === "weight") {
          sums[key] = {
            key,
            value: this.summaryRow.totalOutput,
          };
        } else {
          sums[key] = {
            key,
            value: "",
          };
        }
      });

      return sums;
    },

    getTableListData() {
      const { filterDateRange, filterWorkerNameList, ...reqParamObj } = this.listParam;
      fetchOutputSummaryList(reqParamObj)
        .then((res) => {
          if (res.data.success === 1) {
            const { totalList, name, list } = res.data.body;

            this.summaryRow = { totalOutput: totalList };
            this.allWorkerNameList = name; // res的name 形如 [{name: 'aaa'},{name:'bbb'}]
            this.tableData = list.list;
            this.listDataTotal = list.total;
            this.loading = false;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pageSizeChange(pageSize) {
      this.listParam.pageSize = pageSize;
      this.listParam.pageNum = 1;
      this.getTableListData();
    },
    pageNumberChange(pageNum) {
      this.listParam.pageNum = pageNum;
      this.getTableListData();
    },

    exportTableList() {
      const { pageNum, pageSize, filterDateRange, filterWorkerNameList, ...data } = this.listParam;
      const dataStr = this.qs.stringify(data);
      window.location.href = `${this.baseUrl}/dtsum/zongtong/report/statisticalFormsController/productAmountSelectExprot?${dataStr}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/common.scss";

.container {
}
</style>
